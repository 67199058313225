/* CSS for small to medium screens */
.EEEBGM12 {
  width: 100%;
  height: auto;
  background-image: url("./led1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Plan card styles */
.EEEBGM12 .ForCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 12px;
}

.EEEBGM12 .ForCenter .plan-card {
  border: 1px solid #c4c2c2;
  border-radius: 10px;
  color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 6px 25px rgba(199, 243, 243, 0.641);
}

.EEEBGM12 .ForCenter .plan-card h2 {
  margin-top: 0;
}

.EEEBGM12 .ForCenter .plan-card ul {
  list-style: none;
  padding: 0;
}

/* Different Card Styles */
.EEEBGM12 .ForCenter .self-led,
.EEEBGM12 .ForCenter .acknowledge-pre,
.EEEBGM12 .ForCenter .advanced-mentor-pre {
  background-color: #000000a3;
}
.EEEBGM12 .EEEbtn {
  background-color: #000000; /* Dark button background color */
  color: #ffffff; /* Text color */
  border: none; /* Remove border */
  padding: 10px 20px; /* Add padding */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 4px 4px 8px rgba(255, 255, 255, 0.605), -4px -4px 8px rgba(255, 255, 255, 0.2);
  transition: box-shadow 0.3s ease, transform 0.3s ease; 
  cursor: pointer; 
}

.EEEBGM12 .EEEbtn:hover {
  color: #000000;
  background-color: #c7701e;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.3), -6px -6px 12px rgba(255, 255, 255, 0.3); 
  transform: translateY(-2px);
}

.payment-button-eee {
  display: inline-block;
  background-color: #f3a200; /* Attractive red color */
  color: rgb(0, 0, 0); /* White text for contrast */
  padding: 5px 10px; /* Padding for a larger clickable area */
  font-size: 16px; /* Larger font size for better readability */
  font-weight: bold; /* Bold text for emphasis */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners for a modern look */
  text-decoration: none; /* Remove underline from link */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  cursor: pointer; /* Pointer cursor on hover */
}

.payment-button-eee:hover {
  background-color: #000000; /* Darker red for hover effect */
  color: #f3a200;
}
