.Cont {
    background-color: #0057b35b;
    background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../Home/uf.jpg');

    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    background-attachment: fixed; /* Fix the image in place while scrolling */
}
.contact-container {
    max-width: 600px; /* Limit the width for better responsiveness */
    margin: auto; /* Center the form */
    padding: 20px; /* Padding around the form */
    
  }
  
  .contact-heading {
    font-size: 1.8rem; /* Heading size */
    font-weight: bold; /* Bold heading */
    color: #ffffff; /* Dark color for the heading */

  }
  
  .contact-form {
    border-radius: 8px; /* Rounded corners for the form */
  }
  
  .contact-form-group {
    margin-bottom: 1.5rem; /* Spacing between form groups */
  }
  
  .contact-input, .contact-select, .contact-textarea {
    border: 1px solid #ced4da; /* Light border */
    border-radius: 4px; /* Slight rounding */
    transition: border-color 0.3s; /* Smooth transition */
    
  }
  
  .contact-input:focus, .contact-select:focus, .contact-textarea:focus {
    border-color: #007bff; /* Blue border on focus */
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Shadow effect */
  }
  
  .contact-submit-button {
    background-color: #007bff; /* Button color */
    border: none; /* No border */
    font-weight: bold; /* Bold button text */
  }
  
  .contact-submit-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  @media (max-width: 768px) {
    .contact-heading {
      font-size: 1.5rem; /* Smaller heading on mobile */
    }
  
    .contact-container {
      padding: 10px; /* Less padding on mobile */
    }
  }
  