.DB1BG1{
    width: 100%;
  height: 90vh;
  background-image: url("./Image/DatabaseBG.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center top;
    
}
.DB1BG1 .DB1H1{
    color: white;
    text-align: center;
    padding: 12px;
    background-color: rgb(0, 0, 0);
    
}
.DB1BG1 .DB1H6 {
    color: white;
    display: flex;
    margin: 5%;
    justify-content: auto;
    padding: 12px;
    width: 70%;
    /* text-align: justify;  */
}
