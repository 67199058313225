/* About.css */

/* General styles for the About page */
.AboutBg{
    background-image: url("./AboutBg.jpg");
    width: auto; 
    height: auto;
}


.about-page {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
    font-size: 12px;

    color: #333;
}

/* Header section styles */
.about-header {
    text-align: center;
    margin-bottom: 20px;
}

/* Main content section styles */
.about-content p {
    margin-bottom: 15px;
    font-size: 1rem;
}
.AboutBg{
    height: auto;
}

/* Responsive styles for smaller screens */
@media (max-width: 576px) {
    .about-page {
        padding: 10px;
        max-width: 100%;
    }

    .about-content p {
        font-size: 0.9rem;
    }
}
