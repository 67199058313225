/* Container styles */
.unique-team-cards {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    padding: 20px;
}

/* Card styles */
.unique-card {
    background-color: #f5f9ff;
    border: 1px solid #d0e3ff;
    border-radius: 12px;
    width: 40%; /* You can adjust this width as needed */
    height: auto;
    display: flex;
    flex-direction: row; /* Change to row for side-by-side layout */
    align-items: center;
    text-align: left; /* Align text to the left */
    padding: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

}

/* Hover effect */
.unique-card:hover {
    box-shadow: 0 8px 18px rgba(0, 0, 0, 0.585);
    background-color: #e3f2ff;
    border-color: #a0c4ff9d;
    box-shadow: 12px  12px 10px rgb(0, 0, 0);

}

/* Image styling with custom corners */
.unique-card-image-container {
    flex: 30%; /* Image takes up 30% of card */
    overflow: hidden; /* Prevent overflow */
}

.unique-card-image {
    width: 100%;
    height: auto; /* Adjust height as necessary */
    object-fit: cover;

}

/* Content styling */
.unique-card-content {
    flex: 70%; /* Content takes up 70% of card */
    padding-left: 10px; /* Add some space between image and text */
}

/* Text styling */
.unique-card-name {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin: 5px 0 5px;
}

.unique-card-title {
    font-size: 1rem;
    color: #666;
    margin-bottom: 5px;
}

.unique-card-description {
    font-size: 0.9rem;
    color: #555;
    line-height: 1.4;
}

/* Responsive adjustments */
@media (max-width: 576px) {
    .unique-card {
        width: 90%; /* Make cards full width on small screens */
        flex-direction: column; /* Stack content vertically */
    }
    .unique-card-image-container {
        flex: none; /* Reset flex for image container */
    }
}
