/* External CSS */
.page-is-empty-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .page-is-empty-text {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .link-to-home {
    color: #007bff; /* Bootstrap primary color */
    text-decoration: none;
    font-weight: bold;
  }
  
  .link-to-home:hover {
    text-decoration: underline;
  }
  