.DMBG1{
    width: 100%;
  height: 90vh;
  background-image: url("../Management/Image/DM.png");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center top;
    
}
.DMBG1 .DMH1{
    color: white;
    text-align: center;
    padding: 12px;
    background-color: rgb(0, 0, 0);
    
}
.DMBG1 .DMH6 {
    color: white;
    display: flex;
    margin: 5%;
    justify-content: auto;
    padding: 12px;
    width: 80%;
    /* text-align: justify; */
}
