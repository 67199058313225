/* Custom Job Card Styles */
.job-card {
    border-radius: 10px;
    padding: 20px;
    background-color: #f8f9fa;
    transition: transform 0.2s ease-in-out;
  }
  
  .job-card:hover {
    transform: translateY(-5px);
  }
  
  .job-card-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #007bff;
  }
  
  .job-card-text {
    font-size: 1rem;
    color: #333;
  }
  
  .job-card-body {
    padding: 15px;
  }
  
  /* Modal styling */
  .modal-content {
    border-radius: 10px;
    padding: 20px;
  }
  
  .modal-title {
    font-weight: bold;
    font-size: 1.5rem;
  }
  