.FBG1{
    width: 100%;
  height: 90vh;
  background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../Management/Image/Finace1.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center top;
    
}
.FBG1 .FH1{
    color: white;
    text-align: center;
    padding: 12px;
    background-color: rgb(0, 0, 0);
    
}
.FBG1 .FH6 {
    color: rgb(255, 255, 255);

    display: flex;
    margin: 5%;
    justify-content: auto;
    padding: 12px;
    width: 70%;
    /* text-align: justify;  */
}
