/* src/components/Footer.css */
.footer {
    background-color: #f8f9fa;
    padding: 1rem;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e5e5e5;
  }
  
  .footer a {
    color: #007bff;
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  