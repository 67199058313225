/* Home.css */

/* Style the navigation bar container */
.Hnavbar {
    padding: 0.5%;
    background-image: url("../Images/NavBg.jpg");
    /* background-image: url("./LogoHead.png"); */

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #343a40; /* Fallback color */
}

/* Style the navbar brand */
.navbar-brand {
    color: #f8f9fa; /* Light color */
    font-weight: bold;
    text-decoration: none;
}

/* Style navbar links */
.nav-link {
    color: #ffffff;
    text-decoration: none;
    margin-right: 20px; 
    font-weight: bold;
}

/* Hover effect for navbar links */
.nav-link:hover {
    color: #939699;
}

/* Style the navbar toggler icon */
.navbar-toggler {
    color: #f8f9fa;
}

/* Navbar collapse adjustments */
.navbar-collapse {
    justify-content: flex-end; /* Align items to the right */
}

/* General styling for your custom nav link */
.nav-link-custom {
    color: rgb(161, 200, 255);
    font-weight: bold; /* Make the text bold */
    padding: 10px; /* Adjust padding for spacing */
    text-decoration: none; /* Remove underline */
    transition: color 0.3s; /* Smooth color transition on hover */
}

/* Hover effect for nav links */
.nav-link-custom:hover {
    color: #ffffff; /* Change color on hover */
}
.navbar-toggler {
    width: 15%; /* Set the total width of the icon to 10% */
    height: 40px; /* Adjust the height for better visual appearance */
    display: flex;
    justify-content: center; /* Center the icon horizontally */
    align-items: center; /* Center the icon vertically */
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0; /* Remove any default padding */
}

.navbar-toggler-icon {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center the lines vertically */
    align-items: center; /* Center the lines horizontally */
    height: 100%; /* Ensure the icon spans the full height of the button */
}

.navbar-toggler-icon span {
    display: block;
    width: 80%; /* Adjust width of the lines to be within the button */
    height: 3px;
    background-color: gray;
    transition: transform 0.3s ease, opacity 0.3s ease;
    margin: 3px 0; /* Adjust the spacing between the lines */
    border: none;
    outline: none;
}





/* Media Queries */

/* For extra-small screens (max-width: 576px) */
@media (max-width: 176px) {
    /* Adjust brand font size */
    .navbar-brand {
        font-size: 10px;
    }
    /* Adjust link spacing */
    .nav-link {
        margin-right: 10px;
    }
}
@media (min-width: 276px) {
    /* Adjust brand font size */
    .navbar-brand {
        font-size: 2px;
    }
    /* Adjust link spacing */
    .nav-link {
        margin-right: 10px;
    }
}
@media (min-width: 376px) {
    /* Adjust brand font size */
    .navbar-brand {
        font-size: 10px;
    }
    /* Adjust link spacing */
    .nav-link {
        margin-right: 10px;
    }
}




/* System smallest part */
@media (min-width: 476px) {
    /* Adjust brand font size */
    .navbar-brand {
        font-size: 20px;
    }
    /* Adjust link spacing */
    .nav-link {
        margin-right: 10px;
    }
}



@media (min-width: 576px) {
    /* Adjust brand font size */
    .navbar-brand {
        font-size: 21px;
    }
    /* Adjust link spacing */
    .nav-link {
        margin-right: 10px;
    }
}
@media (min-width: 676px) {
    /* Adjust brand font size */
    .navbar-brand {
        font-size: 21px;
    }
    /* Adjust link spacing */
    .nav-link {
        margin-right: 10px;
    }
}
@media (min-width: 776px) {
    /* Adjust brand font size */
    .navbar-brand {
        font-size: 22px;
    }
    /* Adjust link spacing */
    .nav-link {
        margin-right: 10px;
    }
}
@media (min-width: 876px) {
    /* Adjust brand font size */
    .navbar-brand {
        font-size: 22px;
    }
    /* Adjust link spacing */
    .nav-link {
        margin-right: 10px;
    }
}
@media (min-width: 976px) {
    /* Adjust brand font size */
    .navbar-brand {
        font-size: 23px;
    }
    /* Adjust link spacing */
    .nav-link {
        margin-right: 10px;
    }
}
@media (min-width: 1076px) {
    /* Adjust brand font size */
    .navbar-brand {
        font-size: 24px;
    }
    /* Adjust link spacing */
    .nav-link {
        margin-right: 10px;
    }
}
@media (min-width: 1176px) {
    /* Adjust brand font size */
    .navbar-brand {
        font-size: 25px;
    }
    /* Adjust link spacing */
    .nav-link {
        margin-right: 10px;
    }
}

