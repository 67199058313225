.EV1BG1{
    width: 100%;
    height: 90vh;
    background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),  url("../Mech/Images/CarDesign1.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center top;
      
  }
  .EV1BG1 .EV1H1{
      color: rgb(255, 255, 255);
      text-align: center;
      padding: 12px;
      background-color: rgb(0, 0, 0);
      
  }
  .EV1BG1 .EV1H6 {
      color: rgb(255, 255, 255);
      display: flex;
      margin: 5%;
      justify-content: auto;
      padding: 12px;
      width: 90%;
      /* text-align: justify;  */
  }
  