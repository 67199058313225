/* External CSS file for PaymentProcessingIssue component */

.payment-issue-container {
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    width: 85%;
    margin: 20px auto;
  }
  
  .payment-issue-message {
    font-size: 14px;
    color: #333;
    margin: 0;
  }
  
  .payment-issue-email-link {
    color: #007bff;
    text-decoration: none;
  }
  
  .payment-issue-email-link:hover {
    text-decoration: underline;
  }
  