/* CSS for small to medium screens */
.CIVILBGM12 {
  width: 100%;
  height: auto;
  background-image: url("./Images/Civil15.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Plan card styles */
.CIVILBGM12 .ForCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 12px;
}

.CIVILBGM12 .ForCenter .plan-card {
  border: 1px solid #02cdf1;
  border-radius: 10px;
  color: #000000;
  
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 6px 25px rgba(199, 243, 243, 0.641);
}

.CIVILBGM12 .ForCenter .plan-card h2 {
  margin-top: 0;
}

.CIVILBGM12 .ForCenter .plan-card ul {
  list-style: none;
  padding: 0;
}

/* Different Card Styles */
.CIVILBGM12 .ForCenter .self-led,
.CIVILBGM12 .ForCenter .acknowledge-pre,
.CIVILBGM12 .ForCenter .advanced-mentor-pre {
  background-color: #ecfbffb7;
}
.CIVILBGM12 .CIVILbtn {
  background-color: #000000; /* Dark button background color */
  color: #ffffff; /* Text color */
  border: none; /* Remove border */
  padding: 10px 20px; /* Add padding */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 4px 4px 8px rgba(255, 255, 255, 0.605), -4px -4px 8px rgba(255, 255, 255, 0.2);
  transition: box-shadow 0.3s ease, transform 0.3s ease; 
  cursor: pointer; 
}

.CIVILBGM12 .CIVILbtn:hover {
  color: #ffffff;
  background-color: #0059f3;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.3), -6px -6px 12px rgba(255, 255, 255, 0.3); 
  transform: translateY(-2px);
}
