/* Curriculum.css */

.EEEBG{
  background-image:linear-gradient(rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.3)), url("./6890505.jpg");
  background-size: cover;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  /* border-radius: 50px; */

  background-position: center ;
  color: white;
  font-weight: bold; /* Add font bold */
  

  font-size: 20px;
}


.EEEBG .curriculum-heading {
  padding: 35px;
  text-align:center;
  color: #ffffff; /* Dark text color */
  
}

.EEEBG .accordion-container {
  display: flex;
  justify-content: space-between; /* Spread accordion columns evenly */
  color: #000000;
  
}

.EEEBG .accordion-column {
  flex: 0 0 48%; /* Set width of accordion columns */
}

.EEEBG .accordion-item {
  margin: 20px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.816); /* Add shadow effect to accordion items */
}
/* forButton */
.EEEBG .accordion-title {
  /* background-color: hsla(197, 36%, 75%, 0.773); Light gray background for accordion title */
  padding: 20px;
  background-color: #ffffff8e;
  /* color: white; */
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.EEEBG .accordion-content {
  
  max-height: 0;
  overflow: hidden;
  transition: max-height 1.2s ease-in-out; /* Smooth transition when opening and closing accordion content */
}

.EEEBG .accordion-content.open {
  
  max-height: 500px; /* Adjust the maximum height as needed */
}





/* Small devices (phones, 576px and up) */
@media (min-width: 176px) {
  .EEEBG{
    font-size: 10px;
    color: black;
    font-weight: bold; /* Add font bold */
}
.EEEBG .curriculum ul{
  color: #ffffff;
  font-size: 9px;
  }
}
@media (min-width: 376px) {
  .EEEBG{
    font-size: 11px;

}
.EEEBG .curriculum ul{
  color: #ffffff;
  font-size: 10px;
  }
}
@media (min-width: 576px) {
  .EEEBG{
    font-size: 15px;

}
.EEEBG .curriculum ul{
  
  font-size: 15px;
  color: #ffffff;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  
}