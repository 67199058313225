.industry-container {
  padding: 20px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.484), rgba(195, 190, 190, 0.599)), url("./202985.png");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center top;
}

.industry-header {
  font-size: 30px;
  font-weight: bold;
  padding: 5%;
  width: 100%;
  height: auto;
  border-radius: 8px;
  

  background: 
  linear-gradient(
    rgba(0, 68, 255, 0.59),  /* Adjusts the top color of the first gradient */
    rgba(0, 0, 0, 0.8)   /* Adjusts the bottom color of the first gradient */
  ),
  linear-gradient(
    rgba(0, 0, 0, 0.6),  /* Adjusts the top color of the second gradient */
    rgba(0, 0, 0, 0.8)   /* Adjusts the bottom color of the second gradient */
  ),
  linear-gradient(
    rgba(0, 0, 0, 0.6),  /* Adjusts the top color of the third gradient */
    rgba(253, 250, 255, 0.8)   /* Adjusts the bottom color of the third gradient */
  ),
  linear-gradient(
    rgba(0, 0, 0, 0.6),  /* Adjusts the top color of the fourth gradient */
    rgba(57, 32, 32, 0.8)   /* Adjusts the bottom color of the fourth gradient */
  );
  
  
  text-align: center;
  color: #ffffff;
  box-shadow: 12px 12px 6px rgba(0, 0, 0, 0.551);

}

.industry-grid {
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  /* Ensures a minimum width for each card to adjust the number of columns based on screen size */
}

.industry-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 12px 12px 6px rgba(0, 0, 0, 0.551);
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s;
}

.industry-card:hover {
  transform: translateY(-10px);
  box-shadow: 24px 24px 12px rgba(0, 0, 0, 0.523);
}

.image-wrapper {
  position: relative;
}

.industry-card-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.416);
  transition: opacity 0.3s;
}

.industry-card:hover .gradient-overlay {
  opacity: 0.5;
}

.industry-card-body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 1;
}

.industry-card-title {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.industry-card-text {
  font-size: 14px;
  color: #d1cbcb;
}

/* Responsive adjustments for different screen sizes */
@media (max-width: 1992px) {
  .industry-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 1892px) {
  .industry-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 1792px) {
  .industry-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 1692px) {
  .industry-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 1592px) {
  .industry-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 1492px) {
  .industry-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 1392px) {
  .industry-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 1292px) {
  .industry-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 1192px) {
  .industry-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1092px) {
  .industry-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 992px) {
  .industry-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
}
@media (max-width: 892px) {
  .industry-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
}

@media (max-width: 768px) {
  .industry-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .industry-header {
    font-size: 24px;
  }
}
@media (max-width: 668px) {
  .industry-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .industry-header {
    font-size: 24px;
  }
}

@media (max-width: 576px) {
  .industry-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .industry-header {
    font-size: 20px;
    padding: 10px;
  }
}
@media (max-width: 476px) {
  .industry-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .industry-header {
    font-size: 20px;
    padding: 10px;
  }
}
@media (max-width: 376px) {
  .industry-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .industry-header {
    font-size: 20px;
    padding: 10px;
  }
}
@media (max-width: 276px) {
  .industry-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .industry-header {
    font-size: 20px;
    padding: 10px;
  }
}
