.row {
  display: flex;
  justify-content: space-around;
}

.col {
  flex: 1;
  padding: 20px;
}

.col h2 {
  color: rgb(0, 208, 255);
}

.col ul {
  list-style: none;
  padding: 0;
}

.col ul li {
  margin-bottom: 10px;
  color: rgb(198, 198, 198); /* Set text color to white */
}

.col p {
  color: rgb(198, 198, 198); /* Set text color to white */
}

.SMBg1 {
  width: 100%;
  height: auto;
  background-color: #03070f;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus,
*:active {
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
}

.wrapper {
  display: inline-flex;
  list-style: none;
}

.wrapper1 {
  background-color: #000000;
}

.wrapper .icon {
  position: relative;
  background: #ffffff;
  border-radius: 41%;
  padding: 10px;
  margin: 5px;
  width: 40px;
  height: 40px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #ffffff;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .facebook:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
  background: #1877F2;
  color: #ffffff;
}

.wrapper .whatsapp:hover,
.wrapper .whatsapp:hover .tooltip,
.wrapper .whatsapp:hover .tooltip::before {
  background: #019f75;
  color: #ffffff;
}

.wrapper .twitter:hover,
.wrapper .twitter:hover .tooltip,
.wrapper .twitter:hover .tooltip::before {
  background: #1DA1F2;
  color: #ffffff;
}

.wrapper .instagram:hover,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before {
  background: #E4405F;
  color: #ffffff;
}

.wrapper .github:hover,
.wrapper .github:hover .tooltip,
.wrapper .github:hover .tooltip::before {
  background: #333333;
  color: #ffffff;
}

.wrapper .youtube:hover,
.wrapper .youtube:hover .tooltip,
.wrapper .youtube:hover .tooltip::before {
  background: #CD201F;
  color: #ffffff;
}

.wrapper .gmail:hover,
.wrapper .gmail:hover .tooltip,
.wrapper .gmail:hover .tooltip::before {
  background: #3255a5;
  color: #ffffff;
}



.SMBg1 h2 {
  font-size: 20px; /* Default font size for desktop */
  margin-bottom: 10px;
}

.SMBg1 a,ul {
  font-size: 16px; /* Default link font size for desktop */
}

.SMBg1 p {
  font-size:16px ; /* Address  */
}




    
/* For extra-small screens (max-width: 576px) */
@media (max-width: 176px) {
  /* Adjust brand font size */
  .SMBg1 h2 {
    font-size: 20px; /* Default font size for desktop */
    margin-bottom: 10px;
  }
  
  .SMBg1 a,ul {
    font-size: 16px; /* Default link font size for desktop */
  }

  .SMBg1 p {
    font-size:16px ; /* Address  */
  }

}
@media (min-width: 276px) {
  /* Adjust brand font size */
  .SMBg1 h2 {
    font-size: 20px; /* Default font size for desktop */
    margin-bottom: 10px;
  }
  
  .SMBg1 a,ul {
    font-size: 16px; /* Default link font size for desktop */
  }

  .SMBg1 p {
    font-size:16px ; /* Address  */
  }
}
@media (min-width: 376px) {
  /* Adjust brand font size */
  .SMBg1 h2 {
    font-size: 20px; /* Default font size for desktop */
    margin-bottom: 10px;
  }
  
  .SMBg1 a,ul {
    font-size: 16px; /* Default link font size for desktop */
  }

  .SMBg1 p {
    font-size:16px ; /* Address  */
  }
}




/* System smallest part */
@media (min-width: 476px) {
  /* Adjust brand font size */
  .SMBg1 h2 {
    font-size: 20px; /* Default font size for desktop */
    margin-bottom: 10px;
  }
  
  .SMBg1 a,ul {
    font-size: 16px; /* Default link font size for desktop */
  }

  .SMBg1 p {
    font-size:16px ; /* Address  */
  }
}



@media (min-width: 576px) {
  /* Adjust brand font size */
  .SMBg1 h2 {
    font-size: 20px; /* Default font size for desktop */
    margin-bottom: 10px;
  }
  
  .SMBg1 a,ul {
    font-size: 16px; /* Default link font size for desktop */
  }

  .SMBg1 p {
    font-size:16px ; /* Address  */
  }
}
@media (min-width: 676px) {
  /* Adjust brand font size */
  .SMBg1 h2 {
    font-size: 20px; /* Default font size for desktop */
    margin-bottom: 10px;
  }
  
  .SMBg1 a,ul {
    font-size: 16px; /* Default link font size for desktop */
  }

  .SMBg1 p {
    font-size:16px ; /* Address  */
  }
}
@media (min-width: 776px) {
  /* Adjust brand font size */
  .SMBg1 h2 {
    font-size: 20px; /* Default font size for desktop */
    margin-bottom: 10px;
  }
  
  .SMBg1 a,ul {
    font-size: 16px; /* Default link font size for desktop */
  }

  .SMBg1 p {
    font-size:16px ; /* Address  */
  }
}
@media (min-width: 876px) {
  /* Adjust brand font size */
  .SMBg1 h2 {
    font-size: 20px; /* Default font size for desktop */
    margin-bottom: 10px;
  }
  
  .SMBg1 a,ul {
    font-size: 16px; /* Default link font size for desktop */
  }

  .SMBg1 p {
    font-size:16px ; /* Address  */
  }
}
@media (min-width: 976px) {
  /* Adjust brand font size */
  .SMBg1 h2 {
    font-size: 20px; /* Default font size for desktop */
    margin-bottom: 10px;
  }
  
  .SMBg1 a,ul {
    font-size: 16px; /* Default link font size for desktop */
  }

  .SMBg1 p {
    font-size:16px ; /* Address  */
  }
}
@media (min-width: 1076px) {
  /* Adjust brand font size */
  .SMBg1 h2 {
    font-size: 20px; /* Default font size for desktop */
    margin-bottom: 10px;
  }
  
  .SMBg1 a,ul {
    font-size: 16px; /* Default link font size for desktop */
  }

  .SMBg1 p {
    font-size:16px ; /* Address  */
  }
}
@media (min-width: 1176px) {
  /* Adjust brand font size */
  .SMBg1 h2 {
    font-size: 20px; /* Default font size for desktop */
    margin-bottom: 10px;
  }
  
  .SMBg1 a {
    font-size: 16px; /* Default link font size for desktop */
  }

  .SMBg1 p {
    font-size:16px ; /* Address  */
  }
}