/* Styles for the new class names */
.new-chatbot-container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
}

.new-chatbox {
  width: 300px;
  height: 400px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.new-chat-header {
  background-image: url("./NavBg.jpg");
  color: rgb(11, 0, 0);
  padding: 10px;
  text-align: center;
  position: relative;
}

.new-close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.new-chat-messages {
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
}

.new-message {
  margin: 10px 0;
}

.new-message.user {
  text-align: right;
  color: rgb(0, 0, 0);
}

.new-message.bot {
  text-align: left;
  color: rgb(0, 0, 0);
}

.new-chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
}

.new-chat-input input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
}

.new-chat-input button {
  margin-left: 10px;
  padding: 10px;
  background-color: #001a60;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.new-chatbot-icon {
  width: 60px;
  height: 60px;
  background-color: #000950;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.new-chatbot-logo {
  width: 40px;
  height: 40px;
}

.new-chatbot-tagline {
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
  margin-top: 10px;
  cursor: pointer;
}
