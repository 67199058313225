.WEBBG1{
    width: 100%;
  height: 90vh;
  background-image:linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("../Technology/IMGCSE/WebBG2.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center top;
    /* demo/src/Component/Technology/IMGCSE/WEB2.jpg */
}
.WEBBG1 .WEBH1{
    color: white;
    text-align: center;
    padding: 12px;
    background-color: rgb(0, 0, 0);
    
}
.WEBBG1 .WEBH6 {
    color: rgb(255, 255, 255);
    /* background-color: rgba(205, 205, 205, 0.811); */


    display: flex;
    margin: 5%;
    justify-content: auto;
    padding: 12px;
    width: 90%;
    /* text-align: justify;  */
}
@media (max-width: 768px) {
    .WEBBG1 {
        height: 70vh; /* Reduce the height on tablets */
        background-size: cover; /* Keep the background size consistent */
    }
}

/* Media query for even smaller devices (mobile phones) */
@media (max-width: 280px) {
    .WEBBG1 {
        height: 80vh; /* Further reduce the height on mobile phones */
        background-size: cover; /* Keep the background size consistent */
    }
}


