/* DataViewPage.css */
.PDSTD{
    color: #00438b;
}
/* Container styling */
.data-view-container {
    padding: 20px;
    margin: 0 auto;
    max-width: 85%; /* Adjust width as needed */
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(240, 29, 29, 0.1);
}

/* Headings */
.data-view-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #000000;
}

/* Table styling */
.data-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
}

/* Table header styling */
.data-table thead {
    background-color: #007bff;
    color: #fff;
}

.data-table thead th {
    padding: 10px;
    text-align: left;
    font-weight: bold;
}

/* Table body styling */
.data-table tbody tr {
    border-bottom: 1px solid #ddd;
}

.data-table tbody tr:hover {
    background-color: #f1f1f1; /* Highlight row on hover */
}

.data-table td {
    padding: 10px;
    text-align: left;
    color: #000000;
}

/* Error message styling */
.error {
    color: red;
    text-align: center;
    margin-top: 10px;
}

/* Loading message styling */
.data-view-container p {
    text-align: center;
    color: #333;
}

/* Responsive styling */
@media (max-width: 768px) {
    .data-table thead {
        display: none; /* Hide table headers on small screens */
    }

    .data-table tbody tr {
        display: block; /* Display rows as blocks */
        margin-bottom: 20px; /* Add space between rows */
        border-bottom: 2px solid #ddd;
    }

    .data-table td {
        display: block; /* Display cells as blocks */
        text-align: right; /* Align text right */
        padding: 5px 10px;
        position: relative;
    }

    .data-table td::before {
        content: attr(data-label); /* Show data labels */
        display: block;
        text-align: left;
        font-weight: bold;
        margin-bottom: 5px;
    }
}
