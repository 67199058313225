.CS1BG1{
    width: 100%;
    height: 90vh;
    background-image: 
        linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        url("./Image/Cyber Security1.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center top;
}

.CS1BG1 .CS1H1{
    color: white;
    text-align: center;
    padding: 12px;
    background-color: rgb(0, 0, 0);
    
}
.CS1BG1 .CS1H6 {
    /* background-color: rgba(143, 238, 255, 0.414); */
    color: white;
    display: flex;
    margin: 5%;
    justify-content: auto;
    padding: 12px;
    width: 100%;
    /* text-align: justify;  */
    /* font-size: 15px; */
}
