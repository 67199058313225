/* styles.css */
.MBg2 {
    width: 100%;
    height: auto;
    background-image: url("../Images/Bg6\ \(2\).jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  MBgh{
    color: rgb(255, 255, 255);
  }
  
  
  .with-background-image {
      background-image: url('../Images/txt1.jpg'); 
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
    }
    
  
  .why-to-choose {
      padding: 50px 0;
    }
    
    .reason {
      background-color: #1a1e33fd;
      padding: 30px;
      border-radius: 10px;
      box-shadow: 0px 8px 15px rgba(97, 107, 129, 0.956);
    }
   
    
    .reason h3 {
     color: #89bff8;
     font-weight: bold;
     font-size: 20px;

    }
    
    .reason p {
      color: #cae4fe;
      font-weight: bold;
      font-size: 16px;
    }
    
    
/* For extra-small screens (max-width: 576px) */
@media (max-width: 176px) {
  /* Adjust brand font size */
  .MBg2 .text-center{
    font-size: 18px;
  }
  .reason h3{
    font-size: 14px;
  }
  .reason p {
    font-size: 10px;
  }
  .reason {
    padding: 12px;
  }

}
@media (min-width: 276px) {
  /* Adjust brand font size */
  .MBg2 .text-center{
    font-size: 18px;
  }
  .reason h3{
    font-size: 14px;
  }
  .reason p {
    font-size: 10px;
  }
  .reason {
    padding: 14px;
  }
}
@media (min-width: 376px) {
  /* Adjust brand font size */
  .MBg2 .text-center{
    font-size: 22px;
  }
  .reason h3{
    font-size: 15px;
  }
  .reason p {
    font-size: 11px;
  }
  .reason {
    padding: 16px;
  }
}




/* System smallest part */
@media (min-width: 476px) {
  /* Adjust brand font size */
  .MBg2 .text-center{
    font-size: 25px;
  }
  .reason h3{
    font-size: 15px;
  }
  .reason p {
    font-size: 11px;
  }
  .reason {
    padding: 16px;
  }
}



@media (min-width: 576px) {
  /* Adjust brand font size */
  .MBg2 .text-center{
    font-size: 25px;
  }
  .reason h3{
    font-size: 15px;
  }
  .reason p {
    font-size: 11px;
  }
  .reason {
    padding: 24px;
  }
}
@media (min-width: 676px) {
  /* Adjust brand font size */
  .MBg2 .text-center{
    font-size: 27px;
  }
  .reason h3{
    font-size: 15px;
  }
  .reason p {
    font-size: 11.5px;
  }
  .reason {
    padding: 25px;
  }
}
@media (min-width: 776px) {
  /* Adjust brand font size */
  .MBg2 .text-center{
    font-size: 28px;
  }
  .reason h3{
    font-size: 16px;
  }
  .reason p {
    font-size: 12.5px;
  }
  .reason {
    padding: 26px;
  }
}
@media (min-width: 876px) {
  /* Adjust brand font size */
  .MBg2 .text-center{
    font-size: 29px;
  }
  .reason h3{
    font-size: 16px;
  }
  .reason p {
    font-size: 13px;
  }
  .reason {
    padding: 27px;
  }
}
@media (min-width: 976px) {
  /* Adjust brand font size */
  .MBg2 .text-center{
    font-size: 30px;
  }
  .reason h3{
    font-size: 18px;
  }
  .reason p {
    font-size: 14px;
  }
  .reason {
    padding: 28px;
  }
}
@media (min-width: 1076px) {
  /* Adjust brand font size */
  .MBg2 .text-center{
    font-size: 30px;
  }
  .reason h3{
    font-size: 18px;
  }
  .reason p {
    font-size: 14px;
  }
  .reason {
    padding: 29px;
  }
}
@media (min-width: 1176px) {
  /* Adjust brand font size */
  .MBg2 .text-center{
    font-size: 32px;
  }
  .reason h3{
    font-size: 20px;
  }
  .reason p {
    font-size: 16px;
  }
  .reason {
    padding: 30px;
  }
}
