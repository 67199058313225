.nenu{
    /* background-image:linear-gradient(rgba(255, 255, 255, 0.777), rgba(255, 229, 99, 0.289)), url("./4614022.jpg"); */
background-color: rgba(205, 232, 255, 0.852);
}
.custom-heading {
    position: fixed; /* Makes the heading fixed */
    top: 0; /* Positions the heading at the top of the viewport */
    left: 50%; /* Centers the heading horizontally */
    transform: translateX(-50%); /* Adjusts the heading to be truly centered */
    background: 
        linear-gradient(
            rgba(0, 68, 255, 0.59),  /* Top color of the first gradient */
            rgba(0, 0, 0, 0.8)   /* Bottom color of the first gradient */
        ),
        linear-gradient(
            rgba(0, 0, 0, 0.6),  /* Top color of the second gradient */
            rgba(0, 0, 0, 0.8)   /* Bottom color of the second gradient */
        ),
        linear-gradient(
            rgba(0, 0, 0, 0.6),  /* Top color of the third gradient */
            rgba(253, 250, 255, 0.8)   /* Bottom color of the third gradient */
        ),
        linear-gradient(
            rgba(0, 0, 0, 0.6),  /* Top color of the fourth gradient */
            rgba(57, 32, 32, 0.8)   /* Bottom color of the fourth gradient */
        );
    padding: 25px;
    color: aliceblue;
    width: 100%;
    z-index: 1000; /* Ensures the heading stays above other content */
}
