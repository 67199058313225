/* Default styles */
/* Global styles */
.text-center {
  text-align: center;
}

/* Background styles */
.Csebg {
  width: 100%;
  height: 90vh;
  background-image: url("../Images/CSE/CSEBG1.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center top;
}

/* Header styles */
.CseHd .CseH1 {
  padding: 1%;
  text-align: center;
  font-size: 3rem;
  color: #ffffff;
  background-color: #000000;
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

/* Additional background styles */

.csebg4{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: auto;
  padding: 15px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.csebg5 {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: auto;
  padding: 50px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.cseBg3 {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: auto;
  padding: 12px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.cseBg3 {
  background-image: url("../Images/CSE/CSEBG13.jpg");
}

.csebg4 {
  background-image:linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url("../Images/CSE/5453688.jpg");
  /* background-image: url("../Images/CSE/3147142.jpg"); */
  color: #000000;
}

.csebg5 {
  background-image: url("../Images/CSE/csebg8.jpg");
}

/* Main content styles */
.csebg4 {
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.csebg4 ul {
  list-style: none;
  padding: 3%;
  margin: 3%;
}

.csebg4 li {
  margin-bottom: 10px;
}

.csebg4 ul li strong {
  font-size: 25px;
  color: #16005e;
}

.csebg4 ul {
  font-size: 1.2rem;
}

/* Plan card styles */
.csebg5 .ForCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.csebg5 .ForCenter .plan-card {
  border: 1px solid #c4c2c2;
  border-radius: 10px;
  color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 6px 25px rgba(199, 243, 243, 0.641);
}

.csebg5 .ForCenter .plan-card h2 {
  margin-top: 0;
}

.csebg5 .ForCenter .plan-card ul {
  list-style: none;
  padding: 0;
}

/* Different Card Styles */
.csebg5 .ForCenter .self-led,
.csebg5 .ForCenter .acknowledge-pre,
.csebg5 .ForCenter .advanced-mentor-pre {
  background-color: #0000007b;
}

/* Additional styles */
.postcard {
  flex-wrap: wrap;
  display: flex;
  box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
  border-radius: 10px;
  margin: 0 0 2rem 0;
  overflow: hidden;
  position: relative;
  color: #ffffff;
}

.postcard__text {
  flex: 1;
  padding: 20px;
}

.postcard__img_link {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.postcard__img {
  max-height: 180px;
  width: auto;
}
.btncsestxt{
  text-decoration: none;
  display: block;
  text-align: center;
  font-size: 20px;
}

.side-img {
  width: 100%;
  height: auto;
}
@media (max-width: 320px) {
  .CseHd .CseH1 {
    font-size: 20px;
    padding: 10px;
  }
  .csebg4 {
    font-family: Arial, sans-serif;
    line-height: 1.2;
  }
  .csebg4 ul {
    list-style: none;
    padding: 0.5%;
    margin: 0.5%;
  }
  .csebg4 ul li strong {
    font-size: 12px;
    color: #00CCFF;
  }
  .csebg4 ul {
    font-size: 10px;
  }
  .btncsestxt{
    font-size: 11px;
  }
}

@media (max-width: 480px) {
  .CseHd .CseH1 {
    font-size: 22px;
    padding: 12px;
  }
  .csebg4 {
    font-family: Arial, sans-serif;
    line-height: 1.3;
  }
  .csebg4 ul {
    list-style: none;
    padding: 1%;
    margin: 1%;
  }
  .csebg4 ul li strong {
    font-size: 13px;
    color: #00CCFF;
  }
  .csebg4 ul {
    font-size: 12px;
  }
  .btncsestxt{
    font-size: 11px;
  }
}

@media (max-width: 768px) {
  .CseHd .CseH1 {
    font-size: 24px;
    padding: 14px;
  }
  .csebg4 {
    font-family: Arial, sans-serif;
    line-height: 1.4;
  }
  .csebg4 ul {
    list-style: none;
    padding: 1.5%;
    margin: 1.5%;
  }
  .csebg4 ul li strong {
    font-size: 14px;
    color: #00CCFF;
  }
  .csebg4 ul {
    font-size: 13px;
  }
  .btncsestxt{
    font-size: 11px;
  }
}

@media (max-width: 992px) {
  .CseHd .CseH1 {
    font-size: 26px;
    padding: 16px;
  }
  .csebg4 {
    font-family: Arial, sans-serif;
    line-height: 1.5;
  }
  .csebg4 ul {
    list-style: none;
    padding: 2%;
    margin: 2%;
  }
  .csebg4 ul li strong {
    font-size: 15px;
    color: #00CCFF;
  }
  .csebg4 ul {
    font-size: 14px;
  }
  .btncsestxt{
    font-size: 11px;
  }
}

@media (max-width: 1200px) {
  .CseHd .CseH1 {
    font-size: 28px;
    padding: 18px;
  }
  .csebg4 {
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  .csebg4 ul {
    list-style: none;
    padding: 2.5%;
    margin: 2.5%;
  }
  .csebg4 ul li strong {
    font-size: 16px;
    color: #00CCFF;
  }
  .csebg4 ul {
    font-size: 15px;
  }
  .btncsestxt{
    font-size: 13px;
  }
}

@media (max-width: 1568px) {
  .CseHd .CseH1 {
    font-size: 30px;
    padding: 20px;
  }
  .csebg4 {
    font-family: Arial, sans-serif;
    line-height: 1.7;
  }
  .csebg4 ul {
    list-style: none;
    padding: 3%;
    margin: 3%;
  }
  .csebg4 ul li strong {
    font-size: 17px;
    color: #00CCFF;
  }
  .csebg4 ul {
    font-size: 16px;
  }
}
.csebtn {
  background-color: #000000; /* Dark button background color */
  color: #00f2ff; /* Text color */
  border: none; /* Remove border */
  padding: 10px 20px; /* Add padding */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 4px 4px 8px rgba(32, 194, 230, 0.605), -4px -4px 8px rgba(255, 255, 255, 0.2);
  transition: box-shadow 0.3s ease, transform 0.3s ease; 
  cursor: pointer; 
}

.csebtn:hover {
  color: #355e86;
  background-color: #00f2ff;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.3), -6px -6px 12px rgba(255, 255, 255, 0.3); 
  transform: translateY(-2px);
}

  /* Small devices (phones, up to 576px) */
  @media (max-width: 576px) {
    .MBg{
        width: 100%;
        height: 40vh;
    }
    .Mtext .mh1 {
        padding: 20px;
      font-size: 15px; /* Adjust font size for small devices */
    }
    .btncsestxt{
      font-size: 14px;
    }
  }
  @media (min-width: 676px) {
    .MBg{
        width: 100%;
        height: 40vh;
    }
    .Mtext .mh1 {
        padding: 20px;
      font-size: 15px; /* Adjust font size for small devices */
    }
    .btncsestxt{
      font-size: 12px;
    }
  }
  @media (min-width: 776px) {
    .MBg{
        width: 100%;
        height: 50vh;
    }
    .Mtext .mh1 {
        padding: 20px;
      font-size: 20px; /* Adjust font size for small devices */
    }


    .btncsestxt{
      font-size: 15px;
    }
    .btncsestxt{
      font-size: 14px;
    }

  }
  @media (min-width: 976px) {
    .MBg{
        width: 100%;
        height: 60vh;
    }
    .Mtext .mh1 {
        padding: 20px;
      font-size: 28px; /* Adjust font size for small devices */
    }
    .btncsestxt{
      font-size: 16px;
    }
  }
  @media (min-width: 1176px) {
    .MBg{
        width: 100%;
        height: 70vh;
    }
    .Mtext .mh1 {
        padding: 8%;
        font-size: 29px; /* Adjust font size for small devices */
    }
    .btncsestxt{
      font-size: 18px;
    }
  }
  @media (min-width: 1376px) {
    .MBg{
        width: 100%;
        height: 90vh;
    }
    .Mtext .mh1 {
        padding: 8%;
      font-size: 29px; /* Adjust font size for small devices */
    }

  }