/* Adjusting existing CSS for better responsiveness with Bootstrap */
.marquee {
    overflow: hidden;
    position: relative;
  }
 
  .marquee-content {
    display: flex;

    animation: marqueeScroll 30s linear infinite;
  }
  .icon {
    font-size: 30px; /* Adjust the size as needed */
  }
  
  /* Adjust the scroll speed for smaller screens */
  .marquee-content li {
    list-style: none;

  }
  
  /* Icon hover effect */
  .marquee-content .fa:hover {
    transform: scale(1.1);
  }
  
  /* Keyframes for marquee scroll animation */
  @keyframes marqueeScroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-300%);
    }
  }
  /* For extra-small screens (max-width: 576px) */
@media (max-width: 176px) {
  /* Adjust brand font size */
  .icon {
    font-size: 18px; /* Adjust the size as needed */
  }

}

@media (min-width: 276px) {
  /* Adjust brand font size */
  .icon {
    font-size: 19px; /* Adjust the size as needed */
  }
}

@media (min-width: 376px) {
  /* Adjust brand font size */
  .icon {
    font-size: 20px; /* Adjust the size as needed */
  }
}

@media (min-width: 476px) {
  /* Adjust brand font size */
  .icon {
    font-size: 21px; /* Adjust the size as needed */
  }
}

@media (min-width: 576px) {
  /* Adjust brand font size */
  .icon {
    font-size: 22px; /* Adjust the size as needed */
  }
}

@media (min-width: 676px) {
  /* Adjust brand font size */
  .icon {
    font-size: 23px; /* Adjust the size as needed */
  }
}

@media (min-width: 776px) {
  /* Adjust brand font size */
  .icon {
    font-size: 24px; /* Adjust the size as needed */
  }
}

@media (min-width: 876px) {
  /* Adjust brand font size */
  .icon {
    font-size: 25px; /* Adjust the size as needed */
  }
}

@media (min-width: 976px) {
  /* Adjust brand font size */
  .icon {
    font-size: 26px; /* Adjust the size as needed */
  }
}

@media (min-width: 1076px) {
  /* Adjust brand font size */
  .icon {
    font-size: 27px; /* Adjust the size as needed */
  }
}

@media (min-width: 1176px) {
  /* Adjust brand font size */
  .icon {
    font-size: 28px; /* Adjust the size as needed */
  }
}