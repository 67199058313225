.text-center123 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }
  
  .counter-box {
    width: 100%;
    height: 100%;
    padding: 20px;
    margin: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .counter-icon {
    font-size: 50px;
    margin-bottom: 15px;
    color: #007bff;
  }
  
  .counter-box:hover {
    transform: translateY(-10px);
  }
  
  .counter-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  
  .counter {
    font-size: 50px;
    font-weight: bold;
  }
  
  p {
    font-size: 1em;
    margin-top: 10px;
  }
  
  /* Media queries for responsive adjustments */
  @media (max-width: 768px) {
    .text-center123 {
      height: auto;
    }
    .counter-box {
      width: 100%;
      height: auto;
      padding: 15px;
      margin: 10px 0;
    }
    .counter-icon {
      font-size: 40px;
    }
    .counter {
      font-size: 30px;
    }
    p {
      font-size: 14px;
    }
  }
  
  @media (max-width: 576px) {
    .text-center123 {
      height: auto;
    }
    .counter-box {
      width: 100%;
      height: auto;
      padding: 10px;
      margin: 5px 0;
    }
    .counter-icon {
      font-size: 30px;
    }
    .counter {
      font-size: 25px;
    }
    p {
      font-size: 12px;
    }
  }
  