
.Codh3 {
  background-color: black;
  color: white;
  text-align: center;
  padding: 20px;
}


.codBg3 .codh6 {
  color: white;
  padding: 20px;
}
.codBg3 {
  background-image: url("../Code/Images/Codbg1.jpg");
  
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: auto; /* Adjust height as needed */
  padding: 12px; /* Add padding to create space */
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
/* styles.css */

.eeebg4 {
  background-image: url("../Code/Images/codbg2.jpg");
  background-size: cover;
  color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  height: auto; /* Adjust height as needed */
  padding: 20px; /* Add padding to create space */
  justify-content: space-around;
  flex-wrap: wrap;
}

.eeebg4 .text-center {
  text-align: center;
}

.eeebg4 .csebg4 {
  font-family: Arial, sans-serif; /* Change to your preferred font */
  line-height: 1.6; /* Adjust line height as needed */
}

.eeebg4 .csebg4 ul {
  list-style: none;
  padding: 4%;
  margin: 4%;
}

.eeebg4 .csebg4 li {
  margin-bottom: 10px; 
}

.eeebg4 .csebg4 ul li strong {
  font-size: 25px; /* Set font size for strong elements */
  color: #00CCFF; /* Set color for strong elements */
}

.eeebg4 .csebg4 ul {
  font-size: 1.2rem; /* Set font size for list items */
}

.eeebg5{
  width: 100%;
    height: 90vh;
    background-image: url("./EEEIMG/led1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.eeebg5 .ForCenter{
  
    display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 65vh; /* Adjust height as needed */
}

.eeebg5 .ForCenter .plan-card {
  border: 1px solid #c4c2c2;
  border-radius: 10px;
  color: #000000;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 6px 25px rgba(102, 104, 104, 0.641); /* Add shadow to the cards */

}

.eeebg5 .ForCenter .plan-card h2 {
  margin-top: 0;
}

.eeebg5 .ForCenter .plan-card ul {
  list-style: none;
  padding: 0;
}

/* Different Card Styles */
.eeebg5 .ForCenter .self-led {
  background-color: #e3bfb07b;
}

.eeebg5 .ForCenter .acknowledge-pre {
  background-color: #e3bfb07b;
}

.eeebg5 .ForCenter .advanced-mentor-pre {
  background-color: #e3bfb07b;
}




.postcard {
    flex-wrap: wrap;
    display: flex;
    box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
    border-radius: 10px;
    margin: 0 0 2rem 0;
    overflow: hidden;
    position: relative;
    color: #ffffff;
  }
  
  .postcard__text {
    flex: 1;
    padding: 20px;
  }
  
  .postcard__img_link {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  
  .postcard__img {
    max-height: 180px;
    width: auto;
  }
  
  /* Add more CSS styles as needed */
  /* Additional CSS styles can be added here */
.side-img {
    width: 100%;
    height: auto;
  }




  
/* Styling for the heading */
.Mg4 .heading {
  color: #8fb1ff; /* Blue color */
  padding: 25px;
  text-align: center;
}

/* Base hover effect for all cards */
.Mg4 .card {
background-color: #182b58c2;

}
.Mg4 .card:hover {
  transform: scale(1.2); /* Adjust the scale as desired for the zoom effect */
  transition: transform 0.5s ease; /* Smooth transition on hover */

}

/* Hover effect for CSE card */
.Mg4 .cse-card:hover {
  background-color: #8fb1ffe7; /* Blue color for CSE */
  color: aliceblue;
}

/* Hover effect for MECH card */
.Mg4 .mech-card:hover {
  background-color: #8fb1ffe7; /* Blue color for CSE */
}

/* Hover effect for ECE/EEE card */
.Mg4 .ece-eee-card:hover {
  background-color: #8fb1ffe7; /* Blue color for CSE */
}

/* Hover effect for Free Coding Class card */
.Mg4 .coding-card:hover {
  /* CIVBTN */
  background-color: #8fb1ffe7; /* Blue color for CSE */

}

/* Hover effect for Civil card */
.Mg4 .civil-card:hover {
  background-color: #8fb1ffe7; /* Blue color for CSE */
}

/* Hover effect for Management card */
.Mg4 .management-card:hover {
  background-color: #8fb1ffe7; /* Blue color for CSE */
}

/* Styling for card title */
.Mg4 .card-title {
  color: #333;
  font-weight: bold;
  color: aliceblue;

}

/* Additional styling for card body */
.Mg4 .card-body {
  text-align: center;

}

/* Styling for card text */
.Mg4 .card-text {
  list-style-type: none;
  padding-left: 0;
}

/* Styling for list items in card text */
.Mg4 .card-text li {
  margin-bottom: 5px;
}

/* Prevent anchor text decoration */
.Mg4 a.text-decoration-none {
  text-decoration: none; /* Remove underline from links */
}
