.DS1BG1{
    width: 100%;
  height: 90vh;
  background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./Image/DS1BG.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center top;
    
}
.DS1BG1 .DS1H1{
    color: white;
    text-align: center;
    padding: 12px;
    background-color: rgb(0, 0, 0);
    
}
.DS1BG1 .DS1H6 {
    color: white;
    display: flex;
    margin: 5%;
    justify-content: auto;
    padding: 12px;
    width: 95%;
    /* text-align: justify;  */
}


.btnDS {
    background-color: #000000; /* Dark button background color */
    color: #00f2ff; /* Text color */
    border: none; /* Remove border */
    padding: 10px 20px; /* Add padding */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 4px 4px 8px rgba(32, 194, 230, 0.605), -4px -4px 8px rgba(255, 255, 255, 0.2); /* Add shadows for 3D effect */
    transition: box-shadow 0.3s ease, transform 0.3s ease; /* Add transition effect for smooth hover */
    cursor: pointer; /* Cursor pointer on hover */
  }
  
  .btnDS:hover {
    color: #355e86;
    background-color: #00f2ff;
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.3), -6px -6px 12px rgba(255, 255, 255, 0.3); /* Increase shadow on hover */
    transform: translateY(-2px); /* Slight upward movement on hover */
  }