.EmbeddedBG1{
    width: 100%;
  height: 90vh;
  background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../EEE/IMG/EMDED.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center top;
    
}
.EmbeddedBG1 .EmbeddedH1{
    color: white;
    text-align: center;
    padding: 12px;
    background-color: rgb(0, 0, 0);
    
}
.EmbeddedBG1 .EmbeddedH6 {
    color: white;
    display: flex;
    margin: 5%;
    justify-content: auto;
    padding: 12px;
    width: 90%;
    /* text-align: justify;  */
}
