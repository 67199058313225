/* Container Styling */
.custom-industry-slider {
  padding: 2rem;
  background-color: #f9f9f9;
  text-align: center;
}

/* Heading */
.custom-slider-heading {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1.5rem;
}

/* Slider Inner Wrapper */
.custom-slider-inner {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  max-height: 350px; /* Limit height to show only 3 cards initially */
  overflow: hidden;
  transition: max-height 0.5s ease;
}

/* Expanded state for showing all items */
.custom-slider-inner.show-all {
  max-height: 1000px; /* Increase height to show all items when expanded */
}

/* Card Styling */
.custom-industry-card {
  flex: 1 0 30%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.custom-industry-card:hover {
  transform: translateY(-5px);
}

/* Image Styling */
.custom-card-img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.custom-card-title {
  font-size: 1.2rem;
  color: #007bff;
  margin: 0.75rem 0 0.5rem;
}

/* Button Styling */
.custom-btn-learn-more {
  display: inline-block;
  margin: 0.5rem auto 1rem;
  font-size: 0.9rem;
  padding: 0.5rem 1.2rem;
  color: #fff;
  background-color: #007bff;
  border-radius: 20px;
  transition: background-color 0.3s ease;
}

.custom-btn-learn-more:hover {
  background-color: #0056b3;
}

/* Show More/Less Button */
.toggle-button {
  margin-top: 1rem;
  padding: 0.6rem 1.4rem;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .custom-industry-card {
    flex: 1 0 100%;
  }
}
/* Show More/Less Button Styling */
.toggle-button {
  margin-top: 1.5rem;
  padding: 0.7rem 1.6rem;
  font-size: 1rem;
  color: #fff;
  background: linear-gradient(135deg, #007bff 0%, #00c6ff 100%);
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.toggle-button:hover {
  background: linear-gradient(135deg, #0056b3 0%, #0096d6 100%);
  transform: translateY(-2px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

.toggle-button:active {
  transform: translateY(1px);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

/* Icon rotation animation */
.toggle-icon {
  display: inline-block;
  transition: transform 0.3s ease;
}

.toggle-button.show-all .toggle-icon {
  transform: rotate(180deg);
}