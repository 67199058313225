/* General container styling */
.student-review-container {
  display: flex;
  overflow: hidden; /* Hide horizontal scrollbar */
  padding: 20px;
  background-color: #f9f9f9;
  position: relative;
  width: 100%; /* Make sure it takes full width */
}

.student-reviews-wrapper {
  display: flex;
  animation: scroll 20s linear infinite; /* Adjust speed here */
}

.student-card {
  flex-shrink: 0;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 20px;
  margin-right: 15px;
  min-width: 300px; /* Minimum width for cards */
  max-width: 350px; /* Max width for cards */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease; /* Adding smooth scaling effect */
}

.student-card:hover {
  transform: scale(1.05); /* Card grows slightly on hover */
}

/* Justify the text */
/* .student-card p {
  text-align: justify;
} */

.student-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
}

h3 {
  font-size: 1.25rem;
  font-weight: 700;
  color: #000000;
}

h4 {
  margin-bottom: 10px;
  font-size: 1.25rem;
  font-weight: 700;
  color: #1a0283;
}

p {
  font-size: 0.9rem;
  color: #666;
  line-height: 1.6;
}

/* Keyframes for scrolling effect */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); /* Adjust for the total width of the items */
  }
}

/* Prevent overflow and keep it continuous */
.student-review-container::after {
  content: '';
  flex: 0 0 0px;
}

/* Stop scrolling animation on hover */
.student-review-container:hover .student-reviews-wrapper {
  animation-play-state: paused; /* Stops the animation */
}

/* Remove default scrollbars */
.student-review-container::-webkit-scrollbar {
  display: none;
}

.student-review-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Responsive design for various screen sizes */
@media (max-width: 1000px) {
  .student-card {
    min-width: 300px; /* Adjusted for smaller screens */
    max-width: 350px; /* Adjusted for smaller screens */
  }
}

@media (max-width: 600px) {
  .student-card {
    min-width: 300px; /* Adjusted for smaller screens */
    max-width: 350px; /* Adjusted for smaller screens */
  }
}

@media (max-width: 400px) {
  .student-card {
    min-width: 300px; /* Adjusted for smaller screens */
    max-width: 350px; /* Adjusted for smaller screens */
  }
}
