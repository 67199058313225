/* Full-screen job container */
.job-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(6px);
    padding: 0 20px;
    background-color: rosybrown;

}

/* Centered job content box */
.job-content {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 30px;
    border-radius: 15px;
    max-width: 900px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    width: 100%;
}

/* Header section styling */
.header-section {
    text-align: center;
    padding: 20px;
    background: linear-gradient(135deg, #28a745, #218838);
    border-radius: 10px;
    color: white;
    margin-bottom: 30px;
}

.job-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 5px;
}

.company-name {
    font-size: 1.2rem;
}

/* Notice section styling */
.notice-section {
    background-color: #ffcccc;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.notice {
    font-weight: bold;
    color: #fc0019;
}

/* Information sections */
.info-section h4, .responsibilities-section h3, .technical-section h3, .education-section h3 {
    color: #218838;
    font-weight: 600;
}

/* Button styling */
.btnJOB-apply {
    background-color: #28a745;
    border-radius: 50px;
    padding: 15px 30px;
    color: white;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.3s ease;
}

.btnJOB-apply:hover {
    background-color: #218838;
    transform: scale(1.05);
}

ul {
    list-style-type: square;
    padding-left: 20px;
}

p, li {
    font-size: 1.1rem;
}

/* Responsive design adjustments */
@media screen and (max-width: 768px) {
    .job-title {
        font-size: 2rem;
    }

    .company-name {
        font-size: 1rem;
    }

    .btnJOB-apply {
        font-size: 16px;
        padding: 12px 24px;
    }
}
