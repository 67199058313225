.CPBDBG1{
    width: 100%;
  height: 85vh;
  background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./Images/CPBD.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center top;
    
}
.CPBDBG1 .CPBDH1{
    color: white;
    text-align: center;
    padding: 12px;
    background-attachment: fixed;

    background-color: rgb(0, 0, 0);
   
}
.CPBDBG1 .CPBDH6 {
    color: rgb(255, 255, 255);
    display: flex;
    margin: 5%;
    /* background-color: rgba(255, 255, 255, 0.423); */
    justify-content: auto;
    padding: 12px;
    width: 85%;
    /* text-align: justify; */
}
