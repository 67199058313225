.MBg {
    width: 100%;
    height: 50vh;
    background-image: url("../Images/Mbg1.jpg");
    /* background-image:linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.456)), url("../Images/Mbg1.jpg"); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* display: flex;
    justify-content: flex-end;
    padding-right: 20%; Adjust as needed */
}

.Mtext .mh1 {
    width: 70%; /* Adjust as needed */
    color: transparent; /* Make text color transparent */
    font-size: 30px; /* Adjust font size as needed */
    margin-right: 10%; /* Adjust margin as needed */
    background-image: url("../Images/text2.jpg"); /* Path to your font color image */
    -webkit-background-clip: text; /* Apply background-clip to text */
    background-clip: text; /* Apply background-clip to text */
    /* font-family: 'Eras Bold ITC heading';  */
    /* font-family: 'Eras Bold ITC heading';  */
    /* font-family: 'Sitka Banner Semibold';  */
    /* font-family: 'Gabriola';  */
}

.apply-now-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.apply-now-button:hover {
  background-color: #0056b3;
}

/* Provider.css */

/* Common styles for all devices */
.Mtext .mh1 {
    font-size: 35px; /* Default font size for all devices */
  }
  
  /* Small devices (phones, up to 576px) */
  @media (max-width: 576px) {
    .MBg{
        width: 100%;
        height: 40vh;
    }
    .Mtext .mh1 {
        padding: 20px;
      font-size: 15px; /* Adjust font size for small devices */
    }
  }
  @media (max-width: 676px) {
    .MBg{
        width: 100%;
        height: 40vh;
    }
    .Mtext .mh1 {
        padding: 20px;
      font-size: 15px; /* Adjust font size for small devices */
    }
  }
  @media (min-width: 776px) {
    .MBg{
        width: 100%;
        height: 50vh;
    }
    .Mtext .mh1 {
        padding: 20px;
      font-size: 20px; /* Adjust font size for small devices */
    }
  }
  @media (min-width: 976px) {
    .MBg{
        width: 100%;
        height: 60vh;
    }
    .Mtext .mh1 {
        padding: 20px;
      font-size: 28px; /* Adjust font size for small devices */
    }
  }
  @media (min-width: 1176px) {
    .MBg{
        width: 100%;
        height: 70vh;
    }
    .Mtext .mh1 {
        padding: 8%;
        font-size: 29px; /* Adjust font size for small devices */
    }
  }
  @media (min-width: 1376px) {
    .MBg{
        width: 100%;
        height: 90vh;
    }
    .Mtext .mh1 {
        padding: 8%;
      font-size: 29px; /* Adjust font size for small devices */
    }
  }