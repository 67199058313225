.ARD1BG1{
    width: 100%;
  height: 90vh;
  background-image: url("./Image/ARD1BG.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center top;
    
}
.ARD1BG1 .ARD1H1{
    text-align: center;
    padding: 12px;
    background-color: rgba(165, 164, 164, 0.719);
    
}
.ARD1BG1 .ARD1H6 {
    display: flex;
    
    margin: 5%;
    justify-content: auto;
    padding: 12px;
    width: 95%;
    /* text-align: justify;  */
}
